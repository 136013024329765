import { StoreCodeType } from '../types'

export const storeCodesWithSwitcher: StoreCodeType[] = [
  'gymbeamcom',
  'ru',
  'ua',
]

export const storeCodesSpaEnabled: StoreCodeType[] = [
  'gymbeamcom',
  'gymbeamba',
  'gymbeamrs',
]

export const IS_LOCAL_ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' || false
