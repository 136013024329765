import { ComponentProps } from 'react'

import { Link as IntlLink } from '@/intl-navigation'
import {
  combineUrls,
  containsRuInPathname,
  containsUaInPathname,
  formatUrl,
  removeUaRuLocalesFromUrl,
} from '@/common/utils/url-utils'
import { IS_LOCAL_ENVIRONMENT } from '@/common/constants/store-constants'

export type LinkProps = ComponentProps<typeof IntlLink> & {
  baseUrl?: string
  keepUrl?: boolean
}

export function Link({
  baseUrl,
  href,
  keepUrl = false,
  ...props
}: LinkProps): JSX.Element {
  const combinedUrl = combineUrls(href.toString(), baseUrl)

  if (!IS_LOCAL_ENVIRONMENT) {
    const isRu =
      containsRuInPathname(combinedUrl) || containsRuInPathname(baseUrl || '')
    const isUa =
      containsUaInPathname(combinedUrl) || containsUaInPathname(baseUrl || '')

    const resultUrl = formatUrl({
      baseUrl,
      href: href.toString(),
      isLocalEnvironment: false,
      isRu,
      isUa,
      keepUrl,
    })

    return <IntlLink prefetch={false} href={resultUrl} {...props} />
  }

  const resultUrl = formatUrl({
    baseUrl,
    isRu: false,
    isUa: false,
    href: combinedUrl,
    isLocalEnvironment: true,
    keepUrl,
  })

  return (
    <IntlLink
      prefetch={false}
      href={removeUaRuLocalesFromUrl(resultUrl)}
      {...props}
    />
  )
}
